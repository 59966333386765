const { el, list } = window.redom;

export default class Droplist {
  constructor() {
    this.data = [];
    this.el = el(
      ".droplist",
      (this.$value = el(".value")),
      (this.$icon = el("i.icon.ti.ti-caret-up-down-filled")),
      (this.$options = list(".options", Option, null, { parent: this }))
    );
    this.$options.el.style.display = "none";
    this.cancel = () => {
      window.removeEventListener("click", this.cancel);

      this.opened = false;
      this.$options.el.style.display = "none";
    };
    this.el.onclick = (e) => {
      e.stopPropagation();
      if (!this.opened) {
        this.opened = true;
        this.$options.el.style.display = "";
        window.addEventListener("click", this.cancel);
      }
    };
  }
  set options(data) {
    this._options = data;
    this.$options.update(data);
    if (!this.value) {
      this.value = data[0].value;
    }
  }
  get value() {
    return this._value;
  }
  set value(_value) {
    const option = this._options.find(({ value }) => value === _value);
    this.$value.textContent = (option || {}).text || "";
    if (this.onchange && this._value !== _value) {
      this.onchange();
    }
    this._value = _value;
  }
}

class Option {
  constructor({ parent }) {
    this.parent = parent;
    this.el = el(".option");
  }
  update(data) {
    const { parent } = this;
    const { text, value } = data;
    this.el.textContent = text;
    this.el.onclick = (e) => {
      e.stopPropagation();
      parent.value = value;
      parent.cancel();
    };
  }
}
